

header { 
    background-color: rgba(49, 49, 49, 0.904);
    backdrop-filter: blur(20px);
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 130px 1fr 130px;
    column-gap: 5px;
}

h3, header span { margin-top: 5px; }

h1 { padding-top: 3px;}
.header-clickable { padding-top: 5px; padding-bottom: 5px; }

.header-clickable span {
    margin-top: 5px;
    padding-left: 10px;
    position: absolute;
    
}

.header-clickable:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0);
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.header-clickable img {
    width: 30px;
    padding-top: 4px;
    padding-left: 4px;
}

.header-clickable span {
    color: white;
    font-size: 1.2em;
    letter-spacing: 0.025em;
}



@media only screen and (max-width: 780px) {
    .top-menu-txt {
        display: none;
    }
    header { 
        grid-template-columns: 45px 1fr 45px;
    }
}