#settings-wrapper {
    position: absolute;
    top:0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.171);
    backdrop-filter: blur(1px);
}

#settings-modal {
    background-color: white;
    display: block;
    margin: auto;
    width: 100%;
    max-width: 300px;
    height: 210px;
    margin-top: 10vh;
    border-radius: 20px;
    box-shadow: 0 0 10px black;
}

#settings-modal h2 {
    text-align: center;
    font-weight: normal;
}

#settings-elements {
    display: grid;
    grid-template-columns: 1fr 110px;
    row-gap: 5px;
    margin: 10px;
}

#settings-elements button {
    color: black;
    background-color: rgb(237, 240, 243);
    border: 2px solid rgb(146, 150, 155);
    border-radius: 3px;
    padding: 5px 20px;
    font-size: 1em;
}
#settings-elements button:hover {
    cursor: pointer;
    background-color: rgb(219, 219, 219);;
}

#close-settings {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    color: white;
    background-color: rgb(25, 132, 231);
    border: 2px solid rgb(0, 60, 255);
    border-radius: 3px;
    padding: 5px 20px;
    font-size: 1.15em;
}

#close-settings:hover {
    cursor: pointer;
    background-color: rgb(18, 118, 212);
}