

#ion-table {
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    text-align: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(6, 1fr);
}

#anion-header {
    grid-row: 1;
    grid-column: 1 / span 3;
}

#cation-header {
    grid-row: 1;
    grid-column: 4 / span 3;
}

.ion-box  {
    border: 1px solid black;
    margin: 5px;
}

.ion-box p {
    margin-bottom: 0;
}
.ion-name {
    margin-top: 0;
    padding-bottom: 1em;
}

.ion-col-1 { grid-column: 1; }
.ion-col-2 { grid-column: 2; }
.ion-col-3 { grid-column: 3; }
.ion-col-4 { grid-column: 4; }
.ion-col-5 { grid-column: 5; }
.ion-col-6 { grid-column: 6; }


@media only screen and (max-width: 780px) {

    #ion-table {
        grid-template-columns: repeat(3, 1fr);
    }
    
    .ion-col-4 { grid-column: 1; }
    .ion-col-5 { grid-column: 2; }
    .ion-col-6 { grid-column: 3; }

    #cation-header {
        grid-column: 1 / span 3;
    }

    #anion-header {
        grid-row: 10;
        grid-column: 1 / span 3;
    }

    .anion-chrg-hdr { grid-row: 11; }

    #ion-0 { grid-row: 3; }
    #ion-1 { grid-row: 3; }
    #ion-2 { grid-row: 3; }
    #ion-3 { grid-row: 12; }
    #ion-4 { grid-row: 12; }
    #ion-5 { grid-row: 4; }
    #ion-6 { grid-row: 4; }
    #ion-7 { grid-row: 4; }
    #ion-8 { grid-row: 13; }
    #ion-9 { grid-row: 13; }
    #ion-10 { grid-row: 5; }
    #ion-11 { grid-row: 5; }
    #ion-12 { grid-row: 14; }
    #ion-13 { grid-row: 14; }
    #ion-14 { grid-row: 6; }
    #ion-15 { grid-row: 6; }
    #ion-16 { grid-row: 15; }
    #ion-17 { grid-row: 15; }
    #ion-18 { grid-row: 7; }
    #ion-19 { grid-row: 7; }
    #ion-20 { grid-row: 16; }
    #ion-21 { grid-row: 8; }
    #ion-22 { grid-row: 8; }
    #ion-23 { grid-row: 17; }
    #ion-24 { grid-row: 9; }
    #ion-25 { grid-row: 18; }
    #ion-26 { grid-row: 12; }
    #ion-27 { grid-row: 13; }
    #ion-28 { grid-row: 14; }
    
    
}