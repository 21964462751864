#content {
    width: calc(100%-20px);
    max-width: 1000px;
    background-color: white;
    display: block;
    margin: auto;
    padding: 10px;
    margin-bottom: 50px;
    line-height: 2em;
    margin-top: 50px;
}

#content h1, #content h3 {
    color: black;
}

figure .illustration {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 400px;
}

#content figure figcaption {
    text-align: center;
    font-style: italic;
}