@media only screen and (min-width: 780px) {
    #H      { grid-column:  1;    grid-row: 1; }
    #He     { grid-column: 18;    grid-row: 1; }
    #Li     { grid-column:  1;    grid-row: 2; }
    #Be     { grid-column:  2;    grid-row: 2; }
    #B      { grid-column: 13;    grid-row: 2; }
    #C      { grid-column: 14;    grid-row: 2; }
    #N      { grid-column: 15;    grid-row: 2; }
    #O      { grid-column: 16;    grid-row: 2; }
    #F      { grid-column: 17;    grid-row: 2; }
    #Ne     { grid-column: 18;    grid-row: 2; }
    #Na     { grid-column:  1;    grid-row: 3; } 
    #Mg     { grid-column:  2;    grid-row: 3; } 
    #Al     { grid-column: 13;    grid-row: 3; }
    #Si     { grid-column: 14;    grid-row: 3; }
    #P      { grid-column: 15;    grid-row: 3; }
    #S      { grid-column: 16;    grid-row: 3; }
    #Cl     { grid-column: 17;    grid-row: 3; }
    #Ar     { grid-column: 18;    grid-row: 3; }
    #K      { grid-column:  1;    grid-row: 4; } 
    #Ca     { grid-column:  2;    grid-row: 4; } 
    #Sc     { grid-column:  3;    grid-row: 4; } 
    #Ti     { grid-column:  4;    grid-row: 4; } 
    #V      { grid-column:  5;    grid-row: 4; } 
    #Cr     { grid-column:  6;    grid-row: 4; } 
    #Mn     { grid-column:  7;    grid-row: 4; } 
    #Fe     { grid-column:  8;    grid-row: 4; } 
    #Co     { grid-column:  9;    grid-row: 4; }
    #Ni     { grid-column: 10;    grid-row: 4; }
    #Cu     { grid-column: 11;    grid-row: 4; }
    #Zn     { grid-column: 12;    grid-row: 4; }
    #Ga     { grid-column: 13;    grid-row: 4; }
    #Ge     { grid-column: 14;    grid-row: 4; }
    #As     { grid-column: 15;    grid-row: 4; }
    #Se     { grid-column: 16;    grid-row: 4; }
    #Br     { grid-column: 17;    grid-row: 4; }
    #Kr     { grid-column: 18;    grid-row: 4; }
    #Rb     { grid-column:  1;    grid-row: 5; }
    #Sr     { grid-column:  2;    grid-row: 5; }
    #Y      { grid-column:  3;    grid-row: 5; }
    #Zr     { grid-column:  4;    grid-row: 5; }
    #Nb     { grid-column:  5;    grid-row: 5; }
    #Mo     { grid-column:  6;    grid-row: 5; }
    #Tc     { grid-column:  7;    grid-row: 5; }
    #Ru     { grid-column:  8;    grid-row: 5; }
    #Rh     { grid-column:  9;    grid-row: 5; }
    #Pd     { grid-column: 10;    grid-row: 5; }
    #Ag     { grid-column: 11;    grid-row: 5; }
    #Cd     { grid-column: 12;    grid-row: 5; }
    #In     { grid-column: 13;    grid-row: 5; }
    #Sn     { grid-column: 14;    grid-row: 5; }
    #Sb     { grid-column: 15;    grid-row: 5; }
    #Te     { grid-column: 16;    grid-row: 5; }
    #I      { grid-column: 17;    grid-row: 5; }
    #Xe     { grid-column: 18;    grid-row: 5; }
    #Cs     { grid-column:  1;    grid-row: 6; }
    #Ba     { grid-column:  2;    grid-row: 6; }
    #La     { grid-column:  4;    grid-row: 8; }
    #Ce     { grid-column:  5;    grid-row: 8; }
    #Pr     { grid-column:  6;    grid-row: 8; }
    #Nd     { grid-column:  7;    grid-row: 8; }
    #Pm     { grid-column:  8;    grid-row: 8; }
    #Sm     { grid-column:  9;    grid-row: 8; }
    #Eu     { grid-column: 10;    grid-row: 8; }
    #Gd     { grid-column: 11;    grid-row: 8; }
    #Tb     { grid-column: 12;    grid-row: 8; }
    #Dy     { grid-column: 13;    grid-row: 8; }
    #Ho     { grid-column: 14;    grid-row: 8; }
    #Er     { grid-column: 15;    grid-row: 8; }
    #Tm     { grid-column: 16;    grid-row: 8; }
    #Yb     { grid-column: 17;    grid-row: 8; }
    #Lu     { grid-column: 18;    grid-row: 8; }
    #Hf     { grid-column:  4;    grid-row: 6; }
    #Ta     { grid-column:  5;    grid-row: 6; }
    #W      { grid-column:  6;    grid-row: 6; }
    #Re     { grid-column:  7;    grid-row: 6; }
    #Os     { grid-column:  8;    grid-row: 6; }
    #Ir     { grid-column:  9;    grid-row: 6; }
    #Pt     { grid-column: 10;    grid-row: 6; }
    #Au     { grid-column: 11;    grid-row: 6; }
    #Hg     { grid-column: 12;    grid-row: 6; }
    #Tl     { grid-column: 13;    grid-row: 6; }
    #Pb     { grid-column: 14;    grid-row: 6; }
    #Bi     { grid-column: 15;    grid-row: 6; }
    #Po     { grid-column: 16;    grid-row: 6; }
    #At     { grid-column: 17;    grid-row: 6; }
    #Rn     { grid-column: 18;    grid-row: 6; }
    #Fr     { grid-column:  1;    grid-row: 7; }
    #Ra     { grid-column:  2;    grid-row: 7; }
    #Ac     { grid-column:  4;    grid-row: 9; }
    #Th     { grid-column:  5;    grid-row: 9; }
    #Pa     { grid-column:  6;    grid-row: 9; }
    #U      { grid-column:  7;    grid-row: 9; }
    #Np     { grid-column:  8;    grid-row: 9; }
    #Pu     { grid-column:  9;    grid-row: 9; }
    #Am     { grid-column: 10;    grid-row: 9; }
    #Cm     { grid-column: 11;    grid-row: 9; }
    #Bk     { grid-column: 12;    grid-row: 9; }
    #Cf     { grid-column: 13;    grid-row: 9; }
    #Es     { grid-column: 14;    grid-row: 9; }
    #Fm     { grid-column: 15;    grid-row: 9; }
    #Md     { grid-column: 16;    grid-row: 9; }
    #No     { grid-column: 17;    grid-row: 9; }
    #Lr     { grid-column: 18;    grid-row: 9; }
    #Rf     { grid-column:  4;    grid-row: 7; }
    #Db     { grid-column:  5;    grid-row: 7; }
    #Sg     { grid-column:  6;    grid-row: 7; }
    #Bh     { grid-column:  7;    grid-row: 7; }
    #Hs     { grid-column:  8;    grid-row: 7; }
    #Mt     { grid-column:  9;    grid-row: 7; }
    #Ds     { grid-column: 10;    grid-row: 7; }
    #Rg     { grid-column: 11;    grid-row: 7; }
    #Cn     { grid-column: 12;    grid-row: 7; }
    #Nh     { grid-column: 13;    grid-row: 7; }
    #Fl     { grid-column: 14;    grid-row: 7; }
    #Mc     { grid-column: 15;    grid-row: 7; }
    #Lv     { grid-column: 16;    grid-row: 7; }
    #Ts     { grid-column: 17;    grid-row: 7; }
    #Og     { grid-column: 18;    grid-row: 7; }
}

@media only screen and (max-width: 780px) {
    
    .search-view .element-tile { 
        margin-top: 15px;
        display: none;
    }

    .default-view #H      { display: none !important; }
    .default-view #He     { display: none !important; }
    .default-view #Li     { display: none !important; }
    .default-view #Be     { display: none !important; }
    .default-view #B      { display: none !important; }
    .default-view #C      { display: none !important; }
    .default-view #N      { display: none !important; }
    .default-view #O      { display: none !important; }
    .default-view #F      { display: none !important; }
    .default-view #Ne     { display: none !important; }
    .default-view #Na     { display: none !important; } 
    .default-view #Mg     { display: none !important; } 
    .default-view #Al     { display: none !important; }
    .default-view #Si     { display: none !important; }
    .default-view #P      { display: none !important; }
    .default-view #S      { display: none !important; }
    .default-view #Cl     { display: none !important; }
    .default-view #Ar     { display: none !important; }
    .default-view #K      { display: none !important; } 
    .default-view #Ca     { display: none !important; } 
    .default-view #Sc     { display: none !important; } 
    .default-view #Ti     { display: none !important; } 
    .default-view #V      { display: none !important; } 
    .default-view #Cr     { display: none !important; } 
    .default-view #Mn     { display: none !important; } 
    .default-view #Fe     { display: none !important; }
    .default-view #Co     { display: none !important; }
    .default-view #Ni     { display: none !important; }
    .default-view #Cu     { display: none !important; }
    .default-view #Zn     { display: none !important; }
    .default-view #Ga     { display: none !important; }
    .default-view #Ge     { display: none !important; }
    .default-view #As     { display: none !important; }
    .default-view #Se     { display: none !important; }
    .default-view #Br     { display: none !important; }
    .default-view #Kr     { display: none !important; }
    .default-view #Rb     { display: none !important; }
    .default-view #Sr     { display: none !important; }
    .default-view #Y      { display: none !important; }
    .default-view #Zr     { display: none !important; }
    .default-view #Nb     { display: none !important; }
    .default-view #Mo     { display: none !important; }
    .default-view #Tc     { display: none !important; }
    .default-view #Ru     { display: none !important; }
    .default-view #Rh     { display: none !important; }
    .default-view #Pd     { display: none !important; }
    .default-view #Ag     { display: none !important; }
    .default-view #Cd     { display: none !important; }
    .default-view #In     { display: none !important; }
    .default-view #Sn     { display: none !important; }
    .default-view #Sb     { display: none !important; }
    .default-view #Te     { display: none !important; }
    .default-view #I      { display: none !important; }
    .default-view #Xe     { display: none !important; }
    .default-view #Cs     { display: none !important; }
    .default-view #Ba     { display: none !important; }
    .default-view #La     { display: none !important; }
    .default-view #Ce     { display: none !important; }
    .default-view #Pr     { display: none !important; }
    .default-view #Nd     { display: none !important; }
    .default-view #Pm     { display: none !important; }
    .default-view #Sm     { display: none !important; }
    .default-view #Eu     { display: none !important; }
    .default-view #Gd     { display: none !important; }
    .default-view #Tb     { display: none !important; }
    .default-view #Dy     { display: none !important; }
    .default-view #Ho     { display: none !important; }
    .default-view #Er     { display: none !important; }
    .default-view #Tm     { display: none !important; }
    .default-view #Yb     { display: none !important; }
    .default-view #Lu     { display: none !important; }
    .default-view #Hf     { display: none !important; }
    .default-view #Ta     { display: none !important; }
    .default-view #W      { display: none !important; }
    .default-view #Re     { display: none !important; }
    .default-view #Os     { display: none !important; }
    .default-view #Ir     { display: none !important; }
    .default-view #Pt     { display: none !important; }
    .default-view #Au     { display: none !important; }
    .default-view #Hg     { display: none !important; }
    .default-view #Tl     { display: none !important; }
    .default-view #Pb     { display: none !important; }
    .default-view #Bi     { display: none !important; }
    .default-view #Po     { display: none !important; }
    .default-view #At     { display: none !important; }
    .default-view #Rn     { display: none !important; }
    .default-view #Fr     { display: none !important; }
    .default-view #Ra     { display: none !important; }
    .default-view #Ac     { display: none !important; }
    .default-view #Th     { display: none !important; }
    .default-view #Pa     { display: none !important; }
    .default-view #U      { display: none !important; }
    .default-view #Np     { display: none !important; }
    .default-view #Pu     { display: none !important; }
    .default-view #Am     { display: none !important; }
    .default-view #Cm     { display: none !important; }
    .default-view #Bk     { display: none !important; }
    .default-view #Cf     { display: none !important; }
    .default-view #Es     { display: none !important; }
    .default-view #Fm     { display: none !important; }
    .default-view #Md     { display: none !important; }
    .default-view #No     { display: none !important; }
    .default-view #Lr     { display: none !important; }
    .default-view #Rf     { display: none !important; }
    .default-view #Db     { display: none !important; }
    .default-view #Sg     { display: none !important; }
    .default-view #Bh     { display: none !important; }
    .default-view #Hs     { display: none !important; }
    .default-view #Mt     { display: none !important; }
    .default-view #Ds     { display: none !important; }
    .default-view #Rg     { display: none !important; }
    .default-view #Cn     { display: none !important; }
    .default-view #Nh     { display: none !important; }
    .default-view #Fl     { display: none !important; }
    .default-view #Mc     { display: none !important; }
    .default-view #Lv     { display: none !important; }
    .default-view #Ts     { display: none !important; }
    .default-view #Og     { display: none !important; }
    .default-view #H { 
        display: grid !important; 
        grid-column:  1;    grid-row: 1;
    }
    .default-view #He { 
        display: grid !important; 
        grid-column:  9;    grid-row: 1;
    }
    .default-view #Li { 
        display: grid !important; 
        grid-column:  1;    grid-row: 2;
    }
    .default-view #Be { 
        display: grid !important; 
        grid-column:  2;    grid-row: 2;
    }
    .default-view #B { 
        display: grid !important; 
        grid-column:  4;    grid-row: 2;
    }
    .default-view #C { 
        display: grid !important; 
        grid-column:  5;    grid-row: 2;
    }
    .default-view #N { 
        display: grid !important; 
        grid-column:  6;    grid-row: 2;
    }
    .default-view #O { 
        display: grid !important; 
        grid-column:  7;    grid-row: 2;
    }
    .default-view #F { 
        display: grid !important; 
        grid-column:  8;    grid-row: 2;
    }
    .default-view #Ne { 
        display: grid !important; 
        grid-column:  9;    grid-row: 2;
    }
    .default-view #Na { 
        display: grid !important; 
        grid-column:  1;    grid-row: 3;
    }
    .default-view #Mg { 
        display: grid !important; 
        grid-column:  2;    grid-row: 3;
    }
    .default-view #Al { 
        display: grid !important; 
        grid-column:  4;    grid-row: 3;
    }
    .default-view #Si { 
        display: grid !important; 
        grid-column:  5;    grid-row: 3;
    }
    .default-view #P { 
        display: grid !important; 
        grid-column:  6;    grid-row: 3;
    }
    .default-view #S { 
        display: grid !important; 
        grid-column:  7;    grid-row: 3;
    }
    .default-view #Cl { 
        display: grid !important; 
        grid-column:  8;    grid-row: 3;
    }
    .default-view #Ar { 
        display: grid !important; 
        grid-column:  9;    grid-row: 3;
    }
    .default-view #K { 
        display: grid !important; 
        grid-column:  1;    grid-row: 4;
    }
    .default-view #Ca { 
        display: grid !important; 
        grid-column:  2;    grid-row: 4;
    }
    .default-view #Sc { 
        display: grid !important; 
        grid-column:  3;    grid-row: 4;
    }
    .default-view #Ga { 
        display: grid !important; 
        grid-column:  4;    grid-row: 4;
    }
    .default-view #Ge { 
        display: grid !important; 
        grid-column:  5;    grid-row: 4;
    }
    .default-view #As { 
        display: grid !important; 
        grid-column:  6;    grid-row: 4;
    }
    .default-view #Se { 
        display: grid !important; 
        grid-column:  7;    grid-row: 4;
    }
    .default-view #Br { 
        display: grid !important; 
        grid-column:  8;    grid-row: 4;
    }
    .default-view #Kr { 
        display: grid !important; 
        grid-column:  9;    grid-row: 4;
    }
    .default-view #Rb { 
        display: grid !important; 
        grid-column:  1;    grid-row: 5;
    }
    .default-view #Sr { 
        display: grid !important; 
        grid-column:  2;    grid-row: 5;
    }
    .default-view #Y { 
        display: grid !important; 
        grid-column:  3;    grid-row: 5;
    }
    .default-view #In { 
        display: grid !important; 
        grid-column:  4;    grid-row: 5;
    }
    .default-view #Sn { 
        display: grid !important; 
        grid-column:  5;    grid-row: 5;
    }
    .default-view #Sb { 
        display: grid !important; 
        grid-column:  6;    grid-row: 5;
    }
    .default-view #Te { 
        display: grid !important; 
        grid-column:  7;    grid-row: 5;
    }
    .default-view #I { 
        display: grid !important; 
        grid-column:  8;    grid-row: 5;
    }
    .default-view #Xe { 
        display: grid !important; 
        grid-column:  9;    grid-row: 5;
    }
    .default-view #Cs { 
        display: grid !important; 
        grid-column:  1;    grid-row: 6;
    }
    .default-view #Ba { 
        display: grid !important; 
        grid-column:  2;    grid-row: 6;
    }
    .default-view #Ti { 
        display: grid !important; 
        grid-column:  1;    grid-row: 7;
    }
    .default-view #V { 
        display: grid !important; 
        grid-column:  2;    grid-row: 7;
    }
    .default-view #Cr { 
        display: grid !important; 
        grid-column:  3;    grid-row: 7;
    }
    .default-view #Mn { 
        display: grid !important; 
        grid-column:  4;    grid-row: 7;
    }
    .default-view #Fe { 
        display: grid !important; 
        grid-column:  5;    grid-row: 7;
    }
    .default-view #Co { 
        display: grid !important; 
        grid-column:  6;    grid-row: 7;
    }
    .default-view #Ni { 
        display: grid !important; 
        grid-column:  7;    grid-row: 7;
    }
    .default-view #Cu { 
        display: grid !important; 
        grid-column:  8;    grid-row: 7;
    }
    .default-view #Zn { 
        display: grid !important; 
        grid-column:  9;    grid-row: 7;
    }
    .default-view #Zr { 
        display: grid !important; 
        grid-column:  1;    grid-row: 8;
    }
    .default-view #Nb { 
        display: grid !important; 
        grid-column:  2;    grid-row: 8;
    }
    .default-view #Mo { 
        display: grid !important; 
        grid-column:  3;    grid-row: 8;
    }
    .default-view #Tc { 
        display: grid !important; 
        grid-column:  4;    grid-row: 8;
    }
    .default-view #Ru { 
        display: grid !important; 
        grid-column:  5;    grid-row: 8;
    }
    .default-view #Rh { 
        display: grid !important; 
        grid-column:  6;    grid-row: 8;
    }
    .default-view #Pd { 
        display: grid !important; 
        grid-column:  7;    grid-row: 8;
    }
    .default-view #Ag { 
        display: grid !important; 
        grid-column:  8;    grid-row: 8;
    }
    .default-view #Cd { 
        display: grid !important; 
        grid-column:  9;    grid-row: 8;
    }
}