
#element-modal {
    position: absolute;
    top: 10%;
    align-self: center;
    z-index: 2;
    width: 100%;
    border-radius: 30px;
    background-color: white;
    font-family: 'Sen', sans-serif;
    color: rgb(63, 163, 245);
}

.grid-container {
    display: grid;
    width: 96%;
    padding: 2%;
    grid-template-columns: 1fr 50px;
    grid-gap: 5px;
}

.modal-header {
    grid-column: 1;
    font-size: 40px;
    padding-left: 30px;
}

.modal-exit-btn {
    grid-column: 2;
    background-color: rgba(10, 90, 194, 0.151);
    font-size: 27px;
    width: 50px;
    color: white;
    border: 3px solid rgb(41, 142, 224);
    border-radius: 50%;
}

.modal-exit-btn:hover {
    cursor: pointer;
}

.radioactive-symbol {
    display: none;
    height: 0px;
}

.modal-svg {
    display: none;
}

.modal-text-data {
    font-size: 20px;
    grid-column: 1 / span 2;
    text-align: center;
}

@media only screen and (min-width: 740px) {
    #element-modal {
        background-color: transparent;
        top: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.459);
        border-radius: 0;
    }
    .modal_content {
        width: 720px;
        border-radius: 30px;
        background-color: rgba(243, 243, 243, 0.671);
        backdrop-filter: blur(10px);
        color: black;
        border: 5px solid white;
        font-family: 'Sen', sans-serif;
        margin: auto;
        box-shadow: 5px 10px 10px 0px rgba(0, 0, 0, 0.507);
        margin-top: 10%;
    }
    .grid-container {
        display: grid;
        width: 710px;
        grid-template-columns: 250px 1fr 100px;
        grid-gap: 5px;
        margin: 5px;
        margin-bottom: 20px;
        padding: 0px;
    }
    .modal-header {
        grid-column: 1 / span 2;
        font-size: 40px;
        /* font-family: 'Sen', sans-serif; */
        color: black;
        padding-left: 50px;
    }
    .modal-exit-btn {
        grid-column: 3;
        color: white;
        border: none;
        font-size: 27px;
        border-radius: 25px;
        outline: none;
        margin-left: 40%;
        width: 50%;
    }
    .modal-exit-btn:hover {
        transition-duration: 0.3s;
        background-color: rgb(10, 89, 194);
    }
    .modal-svg {
        display: block;
        grid-row: 2 / span 2;
        padding-left: 20px;
        width: 230px;
    }
    .modal-text-data {
        grid-column: 2;
        grid-row: 2;
        text-align: left;
        font-size: 17px;
        padding-left: 10px;
    }
    .radioactive-symbol {
        display: block;
        grid-column: 3;
        grid-row: 2;
        width: 80px;
        height: auto;
        border-radius: 50px;
        margin-left: 10px;
    }
    .item6 {
        grid-row: 3;
        grid-column: 3;
        opacity: 0;
    }
    .item7 {
        grid-column: 2 / span 2;
    }
    .mobile_radioactive_indictaion {
        display: none;
    }
}