
html {
    /* background-image: url(tactile_noise_dark.png); */
    background-image: url(../imgs/background.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
body {
    font-family: 'Sen', sans-serif;
    overflow-x: hidden !important;
}


h1, h3 {
    margin-top: 0;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: normal;
    margin-bottom: 0;
}
.desktop-only-text { display: inline; }

.hide-me {
    display: none !important;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
 }

#element_search_wrapper {
    width: 100vw;
    z-index: 1;
    position: absolute;
    display: block;
    margin: auto;
}

#element_search_container {
    width: 90%;
    max-width: 500px;
    display: grid;
    margin: auto;
    grid-template-columns: 55px auto 30px 100px;
    grid-template-rows: 40px;
}

#element_search_container img {
    grid-column: 1;
    height: 30px;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 10px;
    border-radius: 20px 0px 0px 20px;
}

#element_search_container img.expanded {
    border-radius: 20px 0px 0px 0px;
}

#element_search_container input {
    -webkit-appearance: none;
    min-width: 150px;
    max-width: 645px;
    max-height: 38px;
    grid-column: 2;
    border: none;
    padding-left: 5px;
    outline: none;
    font-size: 18px;
    border-radius: 0;
}

.white-backgrd {
    background-color: white;
}

#clear-search-btn {
    border: 0;
    color: gray;
    font-size: 2em;
}

#clear-search-btn:hover {
    cursor: pointer;
}

#element_search_container span {
    background-color: rgba(46, 46, 46, 0.534);
    color: white;
    border: 2px solid white;
    font-size: 18px;
    padding-left: 20px;
    padding-top: 7px;
    border-radius: 0px 20px 20px 0px;
}

#element_search_container span:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

#element_search_container span.expanded {
    border-radius: 0px 20px 0px 0px;
}

#filter-accordian {
    grid-column: 1 / span 4;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 0 0 20px 20px;
}

#filter-accordian button {
    font-family: 'Sen', sans-serif;
    font-size: 17px;
    text-align: center;
    background-color: white;
    border: 3px solid white;
}

#filter-accordian button:hover {
    background-color: #7dc4ff;
    cursor: pointer;
}

.slider-menu-icon { display: none; }

.periodic-table-wrapper {
    display: block;
    margin: auto;
}

.periodic-table {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(18, 50px);
    width: max-content;
    column-gap: 5px;
    row-gap: 5px;
}

.element-tile {
    background-color: rgb(189, 189, 189);
    color: white;
    border: 2px solid white;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    display: grid;
    border-radius: 10px;
    transition: 0.25s;
}

.element-tile p {
    font-size: 1.35em;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.element-tile label {
    width: 100%;
    text-align: center;
    font-size: 0.75em;
}

.noble-gas                  { background-color: #5900b3; }
.noble-gas:hover            { color: #5900b3; }
.transition-metal           { background-color: rgb(47, 136, 109); }
.transition-metal:hover     { color: rgb(10, 48, 32); }
.alkali-metal               { background-color: rgb(219, 102, 6); }
.alkali-metal:hover         { color: rgb(201, 0, 0); }
.alkaline-earth-metal       { background-color: rgb(223, 182, 0); }
.alkaline-earth-metal:hover { color: rgb(110, 37, 9); }
.metaux_pauvres             { background-color: rgb(5, 148, 5); }
.metaux_pauvres:hover       { color: rgb(0, 133, 0); }
.metalloid                  { background-color: rgb(0, 179, 90); }
.metalloid:hover            { color: rgb(0, 121, 64); }
.non-metal                  { background-color: #d60707; }
.non-metal:hover            { color: #ad0000; }
.noble_gas                  { background-color: rgb(99, 0, 124); }
.noble_gas:hover            { color: rgb(126, 0, 98); }
.lanthanoid                 { background-color: rgb(66, 154, 255); margin-top: 15px; }
.lanthanoid:hover           { color: rgb(25, 117, 129); }
.actinoid                   { background-color: #1a0077; }
.actinoid:hover             { color: #000099; }
.unknown                    { background-color: rgb(65, 65, 65); }
.unknown:hover              { color: rgb(34, 34, 34); }

.element-tile:hover  { 
    background-color: white; 
    cursor: pointer;
}

.element-tile label:hover, #page-menu:hover { cursor: pointer; }

#side-menu {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-width: 500px;
    background-color: rgba(46, 46, 46, 0.534);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 38px 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 10px !important;
    
    overflow-x: hidden !important;
    transition: 0.4s;
}

#side-menu a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

#side-menu button, #side-menu a {
    font-family: 'Sen', sans-serif;
    font-size: 1.25em;
    letter-spacing: 0.025em;
    color: white;
    background-color: transparent;
    backdrop-filter: blur(10px);
    border: none;
}

#side-menu button:hover, #side-menu a:hover {
    background-color: rgba(46, 46, 46, 0.699); 
    cursor: pointer;
}

.hide-menu {
    width: 0 !important;
}

@media only screen and (max-width: 1000px) {  
    .periodic-table {
        column-gap: 3px;
        row-gap: 3px;
        grid-template-columns: repeat(18, 40px);
    }
    
    .element-tile { padding-top: 5px; }
    
    .element-tile p {
        font-size: 1em;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    .element-tile label {
        display: none;
    }
}

@media only screen and (max-width: 780px) {
    .periodic-table {
        margin-top: 20px;
        grid-template-columns: repeat(9, 50px);
    }
    .element-tile p {
        font-size: 1.5em;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

#slider-menu button {
    background-color: rgba(46, 46, 46, 0.534) !important; 
}
#slider-menu button:hover {
    background-color: white !important; 
}

#slider-title { display: none; }

@media only screen and (max-width: 485px) {
    .desktop-only-text { display: none; }
    .slider-menu-icon { 
        display: block; 
        margin: auto;
        width: 40px;
    }
    .slider-menu-txt {display: none;}
    #slider-title { 
        display: block; 
        color: white;
        text-align: center;
        font-weight: normal;
    }
    #slider-menu button:hover .slider-menu-icon {
        filter: invert(100);
    }
    .periodic-table {
        margin-top: 20px;
        width: calc(100vw - 5px);
        grid-template-columns: repeat(9, 1fr);
    }
    .element-tile p {
        font-size: 1em;
        padding-top: 10px;
    }
}

.centre-text {
    text-align: center;
}

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}